/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { palette } from "src/theme";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonInclude,
  ButtonOrderGenerate,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogComponent from "src/components/Dialog";
import useLayout from "src/hooks/useLayout";
import SearchIcon from "@mui/icons-material/Search";
import useCompany from "src/hooks/useCompany";
import useCompanyContact from "src/hooks/useCompanyContact";
import useShoppingProcessType from "src/hooks/useShoppingProcessType";
import useShoppingProcessItem from "src/hooks/useShoppingProcessItem";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import useUnit from "src/hooks/useUnit";
import Empty from "src/components/Empty";
import { useNavigate } from "react-router-dom";
import useRate from "src/hooks/useRate";
import useShoppingProccessStatus from "src/hooks/useShoppingProccessStatus";
import Swal from "sweetalert2";
import { formatCurrency } from "src/functions/text";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { addHours, format } from "date-fns";
import useProductCompany from "src/hooks/useProductCompany";
import useShoppingProccessRate from "src/hooks/useShoppingProccessRate";
import CloseIcon from "@mui/icons-material/Close";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { iShoppingProcess } from "src/interfaces/shoppingProcess";
import RatePCITem from "./RatePCItem";
import { iCompany } from "src/interfaces/company";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useShoppingProcessPhases from "src/hooks/useShoppingProcessPhases";
import useTable from "src/hooks/useTable";
import { MaskStyle } from "src/styles/globalStyle";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import GridDemands from "./GridDemands";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import DemandItem from "./DemandItem";
import ImportItemDialog from "./ImportItemDialog";
import ImportItemDemandDialog from "./ImportItemDemandDialog";
import useImport from "src/hooks/useImport";
import useParticipatingCompanies from "src/hooks/useParticipatingCompanies";
import shoppingProcessConsumer from "src/services/shoppingProcess";

const numberSumWithPage = 40;

enum ViewItems {
  Negociados = "negociados",
  Demandas = "demandas",
}

const GeneralDate: React.FC = () => {
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const {
    openDialog,
    setOpenDialog,
    openDialogSecondary,
    setOpenDialogSecondary,
    openDialogThree,
    setOpenDialogThree,
    click,
    setClick,
    width,
  } = useLayout();

  const { setFieldOrder, setOrder } = useTable();

  const {
    number,
    setNumber,
    description,
    setDescription,
    type,
    setType,
    monthNumber,
    setMonthNumber,
    setBuyerAdministrator,
    setBuyerAdministratorId,
    contactCompanyId,
    setContactCompanyId,
    contactCompanyName,
    setContactCompanyName,
    shoppingProcesSelect,
    handleNewSalve: handleNewSalveShoppingProcess,
    handleUpdate: handleUpdateShoppingProcess,
    status,
    setStatus,
    observationStatusPC,
    setObservationStatusPC,
    statusProgress,
    handleSelected: handleSelectedShoppingProcess,
    buyerAdministratorValue,
    setBuyerAdministratorValue,
    estimatedValue,
    setEstimatedValue,
    dataInicioLevantamento,
    setDataInicioLevantamento,
    dataFimLevantamento,
    setDataFimLevantamento,
    cartaConvite,
    setCartaConvite,
    dataInicioVigencia,
    setDataInicioVigencia,
    dataFimVigencia,
    setDataFimVigencia,
    endDate,
    setEndDate,
    projectId,
    setProjectId,
    dataInicioProcesso,
    setDataInicioProcesso,
    showViewItems,
    setShowViewItems,
    dialogImport,
    setDialogImport,
  } = useShoppingProcess();

  const {
    getDemands,
    isDialogOpen,
    requestDemands,
    loading: loadingDemand,
    page: pageDemand,
  } = useShoppingProccessDemand();

  const {
    getShoppingProcessType,
    shoppingProcessType,
    loading: loadingShoppingProcessType,
  } = useShoppingProcessType();

  const { getRates } = useRate();

  const {
    loading: loadingShoppingProcessItem,
    getShoppingProcessItem,
    shoppingProcessItem,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelected,
    productSelect,
    setProductSelect,
    setProductId,
    setProductGeneralId,
    setBuyerId,
    referenceValue,
    setReferenceValue,
    setPaymentConditionId,
    setPaymentCondition,
    unitId,
    setUnitId,
    setBuyerName,
    handleNewSalve,
    handleUpdate,
    handleDelete,
    shoppingProcesItemSelect,
    quantity,
    setQuantity,
    setCompanyContactId,
    companyContactValue,
    setCompanyContactValue,
    setCompanyContact,
    setProductCode,
    setProductLabel,
    alvoValue,
    setAlvoValue,
    buyerValue,
    setBuyerValue,
    paymentConditionValue,
    setPaymentConditionValue,
    orderBy,
    setOrderBy,
  } = useShoppingProcessItem();

  const {
    getCompanys,
    loading: loadingCompany,
    loadingSelectSearch,
    companysToSearchBuyer,
    getCompanyBuyer,
    nameSearch,
    setNameSearch,
    openDialogCompanyBuyer,
    setOpenDialogCompanyBuyer,
    page: pageCompany,
    handleChangePage: handleChangePageCompany,
    handleChangeRowsPerPage: handleChangeRowsPerPageCompany,
    typingTimeout,
    setTypingTimeout,
    setValue,
  } = useCompany();

  const { getCompanysContacts, companysContactToSearch } = useCompanyContact();

  const { loading: loadingUnit, unitsToSearch, getUnits } = useUnit();

  const { getShoppingProccessStatus, shoppingProccessStatus } =
    useShoppingProccessStatus();

  const { paymentConditionsToSearch, getPaymentConditions } =
    usePaymentCondition();

  const { callItems } = useImport();

  const {
    products,
    getProducts,
    productsToSearch,
    codeProductCompany,
    setCodeProductCompany: setCodeProduct,
    setDescription: setDescriptionProduct,
    page: pageProduct,
    handleChangePage: handleChangePerPageProduct,
    handleChangeRowsPerPage: handleChangeRowsPerPageProduct,
    description: descriptionProductCompany,
    loading: loadingProductCompany,
    setPage,
  } = useProductCompany();

  const { getTaxaPc, valorTaxaTotal, shoppingProccessRates } =
    useShoppingProccessRate();

  const { getShoppingProcessPhases } = useShoppingProcessPhases();

  const { getCompanysParticipating, participatingCompanies } =
    useParticipatingCompanies();

  const header = [
    {
      id: "code",
      label: translations[param]["code"],
      isOrder: true,
      order: 1,
    },
    {
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    },
    {
      id: "buyer",
      label: translations[param]["buyer"],
      isOrder: true,
      order: 3,
    },
    {
      id: "unit",
      label: translations[param]["unit"],
      isOrder: true,
      order: 4,
    },
    {
      id: "quantity",
      label: translations[param]["quantity"],
      isOrder: true,
      order: 5,
    },
    {
      id: "value_reference",
      label: `${translations[param]["value"]} ${translations[param]["reference"]}`,
      isOrder: true,
      order: 6,
    },
    {
      id: "value_total",
      label: `${translations[param]["value"]} ${translations[param]["total"]}`,
      isOrder: false,
    },
  ];

  useEffect(() => {
    if (shoppingProcesSelect?.id) {
      getProductsToDataDialog(shoppingProcesSelect);
    }
  }, [page, orderBy]);

  useEffect(() => {
    if (shoppingProcesSelect?.id) {
      getShoppingProcessPhases(shoppingProcesSelect?.id);
    }
  }, [page]);

  useEffect(() => {
    getRates();
    getShoppingProccessStatus();
    getCompanys({ active: "true" });
    getShoppingProcessType();
    getUnits();
    getTaxaPc();

    if (shoppingProcesSelect?.id) {
      getCompanysParticipating(shoppingProcesSelect.id);
    }

    setFieldOrder({
      id: "description",
      label: translations[param]["description"],
      isOrder: true,
      order: 2,
    });
    setOrder("asc");

    if (
      shoppingProccessStatus?.find(
        (e) =>
          e.description.toUpperCase() === "Coleta de Demandas".toUpperCase()
      )?.id === status &&
      status
    ) {
      setShowViewItems(ViewItems.Demandas);
    }
  }, []);

  useEffect(() => {
    if (shoppingProcesSelect?.id) {
      getDemands(shoppingProcesSelect.id);
    }
  }, [pageDemand.page, pageDemand.rowsPerPage]);

  useEffect(() => {
    if (shoppingProccessStatus.length !== 0 && !shoppingProcesSelect?.id) {
      const currentStatus: string = shoppingProccessStatus?.find(
        (e) => e.description.toUpperCase() === "Em configuração".toUpperCase()
      )?.id as string;

      if (currentStatus) setStatus(currentStatus);
    }
  }, [shoppingProccessStatus]);

  useEffect(() => {
    if (
      codeProductCompany?.length >= 4 ||
      descriptionProductCompany?.length >= 4
    ) {
      if (shoppingProcesSelect?.id) {
        getProducts(
          shoppingProcesSelect.buyerAdministratorId,
          page.page,
          page.rowsPerPage + 40
        );
      }
    } else if (
      codeProductCompany?.length === 0 &&
      descriptionProductCompany?.length === 0
    ) {
      if (shoppingProcesSelect?.id) {
        getProducts(
          shoppingProcesSelect.buyerAdministratorId,
          page.page,
          page.rowsPerPage + 40
        );
      }
    }
  }, [codeProductCompany, descriptionProductCompany]);

  useEffect(() => {
    const handleTypingTimeout = () => {
      if (nameSearch?.length >= 4) {
        getCompanyBuyer(numberSumWithPage);
      } else if (nameSearch?.length === 0) {
        getCompanyBuyer(numberSumWithPage);
      }
    };

    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(handleTypingTimeout, 1000); // 1000ms = 1 segundo
    setTypingTimeout(newTypingTimeout);

    return () => {
      clearTimeout(newTypingTimeout);
    };
  }, [nameSearch, shoppingProcesItemSelect]);

  useEffect(() => {
    if (openDialogCompanyBuyer || openDialog) {
      getCompanyBuyer(numberSumWithPage);
    }
  }, [openDialog, pageCompany.page]);

  useEffect(() => {
    if (shoppingProcesSelect?.id) callStask(shoppingProcesSelect);
  }, [pageProduct]);

  const callStask = async (data: any) => {
    if (click && data?.id) {
      await getProducts(
        `${data?.buyerAdministratorId}`,
        pageProduct.page,
        pageProduct.rowsPerPage + 40
      );
      setOpenDialogSecondary(true);
    }
  };

  const getProductsToDataDialog = async (data: iShoppingProcess) => {
    if (data?.id) {
      getShoppingProcessItem(data.id);
      getProducts(data.buyerAdministratorId, page.page, page.rowsPerPage + 40);
    }
  };

  useEffect(() => {
    if (callItems && shoppingProcesSelect?.id) {
      getShoppingProcessItem(shoppingProcesSelect.id);
    }
  }, [callItems]);

  if (
    loadingCompany ||
    loadingShoppingProcessType ||
    loadingUnit ||
    loadingDemand
  ) {
    return <CircularLoading />;
  }

  return (
    <>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={3} sm={2} md={1.5}>
                  <FormLabel>{translations[param]["proccess"]}:</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={9} sm={10} md={width >= 1500 ? 10.5 : 12}>
                <TextField
                  size="small"
                  id="process"
                  name="process"
                  label={translations[param]["proccess"]}
                  variant="outlined"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sm={2} md={1.5}>
                {width >= 1500 ? (
                  <FormLabel>{translations[param]["description"]}:</FormLabel>
                ) : null}
              </Grid>
              <Grid item xs={9} sm={10} md={width >= 1500 ? 10.5 : 12}>
                <TextField
                  size="small"
                  id="description"
                  name="description"
                  label={translations[param]["description"]}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sm={2} md={1.5}>
                {width >= 1500 ? (
                  <FormLabel>
                    {translations[param]["management_company"]}
                  </FormLabel>
                ) : null}
              </Grid>
              <Grid
                item
                xs={7}
                sm={8}
                md={width >= 1500 ? 10.5 : 12}
                display="flex"
              >
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={companysToSearchBuyer ?? []}
                  value={buyerAdministratorValue}
                  getOptionLabel={(option) => option?.label ?? ""}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setBuyerAdministratorValue(value);
                      setBuyerAdministratorId(value.id);
                      setBuyerAdministrator(value.label);
                      getCompanysContacts(value.id, 1000);
                      getPaymentConditions({
                        id: value.id,
                      } as iCompany);
                    } else {
                      setBuyerAdministratorValue("");
                      setBuyerAdministratorId("");
                      setBuyerAdministrator("");
                      getCompanysContacts("");
                    }
                  }}
                  renderInput={(params) =>
                    loadingCompany ? (
                      <CircularLoading />
                    ) : (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e) => setNameSearch(e.target.value)}
                      />
                    )
                  }
                  fullWidth
                />
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={() => setOpenDialogCompanyBuyer(true)}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3} sm={2} md={1.5}>
                {width >= 1500 ? (
                  <FormLabel>
                    {translations[param]["management_responsible"]}
                  </FormLabel>
                ) : null}
              </Grid>
              <Grid item xs={9} sm={10} md={width >= 1500 ? 10.5 : 12}>
                {/* here */}
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_responsible"
                  options={companysContactToSearch}
                  value={contactCompanyId}
                  inputValue={contactCompanyName}
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setContactCompanyId(value.id);
                      setContactCompanyName(value.label);
                    } else {
                      setContactCompanyId("");
                      setContactCompanyName("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Selecione:" size="small" />
                  )}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={5} md={4.5}>
                  <FormLabel>{translations[param]["register_date"]}</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                <FormControl fullWidth>
                  <MaskStyle>
                    <input
                      type="date"
                      name="begin_date"
                      placeholder="dd/mm/yyyy"
                      min="1970-01-01"
                      value={dataInicioProcesso}
                      onChange={(e) => {
                        setDataInicioProcesso(e.target.value);
                        setEndDate("");
                      }}
                    />
                  </MaskStyle>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={5} md={4.5}>
                  <FormLabel>
                    {translations[param]["end_date_proccess"]}
                  </FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                <FormControl fullWidth>
                  <MaskStyle>
                    <input
                      type="date"
                      name="begin_date"
                      placeholder="dd/mm/yyyy"
                      min={dataInicioProcesso}
                      disabled={!dataInicioProcesso}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </MaskStyle>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={3}>
                  <FormLabel>{translations[param]["type"]}:</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={width >= 1500 ? 9 : 12}>
                <Select
                  labelId="show_select_type"
                  id="show_select_type"
                  value={type}
                  size="small"
                  onChange={(event: SelectChangeEvent) =>
                    setType(event.target.value as string)
                  }
                  fullWidth
                >
                  <MenuItem value="select" disabled>
                    {width <= 1500
                      ? `${translations[param]["type"]} - ${translations[param]["select"]}`
                      : translations[param]["select"]}
                    :
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {shoppingProcessType.map((type) => (
                    <MenuItem key={type.id} value={`${type.id}`}>
                      {type.description}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={5} md={4.5}>
                  <FormLabel>
                    {translations[param]["estimated_value"]}:
                  </FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                <TextField
                  size="small"
                  id="calculateTotalShoppingProcess"
                  name="calculateTotalShoppingProcess"
                  variant="outlined"
                  fullWidth
                  value={estimatedValue}
                  onChange={(e) => {
                    e = formatCurrency(e);
                    setEstimatedValue(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={5} md={4.5}>
                  <FormLabel>{translations[param]["validity"]}:</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                <TextField
                  size="small"
                  id="monthNumber"
                  name="monthNumber"
                  label={translations[param]["validity"]}
                  value={monthNumber}
                  onChange={(e) => setMonthNumber(e.target.value)}
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={3}>
                  <FormLabel>{translations[param]["project"]}:</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={width >= 1500 ? 9 : 12}>
                <Select
                  labelId="show_select_ProjectId"
                  id="show_select_ProjectId"
                  disabled
                  value={projectId}
                  size="small"
                  onChange={(event: SelectChangeEvent) =>
                    setProjectId(event.target.value as string)
                  }
                  fullWidth
                >
                  <MenuItem value="select" disabled>
                    {width <= 1500
                      ? `${translations[param]["project"]} - ${translations[param]["select"]}`
                      : translations[param]["select"]}
                    :
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={5} md={4.5}>
                  <FormLabel>{translations[param]["value"]}:</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                <TextField
                  size="small"
                  id="calculateTotalShoppingProcess"
                  name="calculateTotalShoppingProcess"
                  value={
                    shoppingProcesSelect?.totalValue?.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }) ?? "0,00"
                  }
                  variant="outlined"
                  disabled={
                    !shoppingProcesSelect?.id &&
                    shoppingProccessStatus?.find(
                      (e) =>
                        e.description.toUpperCase() ===
                        "Em configuração".toUpperCase()
                    )?.id === status
                      ? false
                      : true
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={5} md={4.5}>
                  <FormLabel>
                    {`${translations[param]["value"]} ${translations[param]["rate"]} total`}
                    :
                  </FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    id="taxa_total"
                    name="taxa_total"
                    label={`${translations[param]["value"]} ${translations[param]["rate"]} total`}
                    value={`${valorTaxaTotal}%`}
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container alignItems="center" spacing={2}>
              {width >= 1500 ? (
                <Grid item xs={3}>
                  <FormLabel>{translations[param]["status"]}:</FormLabel>
                </Grid>
              ) : null}
              <Grid item xs={width >= 1500 ? 9 : 12}>
                <Select
                  labelId="show_select_status"
                  id="show_select_status"
                  value={status}
                  size="small"
                  required
                  onChange={(event: SelectChangeEvent) => {
                    const statusId = event.target.value as string;
                    setStatus(statusId);

                    if (
                      shoppingProccessStatus.find((e: any) => e.id === statusId)
                        ?.description === "Coleta de Demandas"
                    ) {
                      setShowViewItems(ViewItems.Demandas);
                    }

                    if (
                      shoppingProccessRates.length === 0 &&
                      (shoppingProccessStatus.find(
                        (e: any) => e.id === statusId
                      )?.description === "Em andamento" ||
                        shoppingProccessStatus.find(
                          (e: any) => e.id === statusId
                        )?.description === "Em consumo")
                    ) {
                      Swal.fire({
                        title: translations[param]["attention"],
                        text: "Processo de compras com taxa não cadastrada, por favor indicar a taxa!",
                        icon: "warning",
                        confirmButtonColor: palette.primary.main,
                        cancelButtonColor: palette.error.main,
                        confirmButtonText: "Cadastrar",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setValue(3);
                        }
                      });
                    }
                  }}
                  fullWidth
                >
                  <MenuItem value="select" disabled>
                    {width >= 1500
                      ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                      : translations[param]["select"]}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {shoppingProccessStatus.map((status) => (
                    <MenuItem key={status.id} value={`${status.id}`}>
                      {status.description}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {shoppingProccessStatus?.find(
            (e) =>
              e.description.toUpperCase() === "Coleta de Demandas".toUpperCase()
          )?.id === status &&
            status && (
              <Grid item xs={12} sm={6} md={4}>
                <Grid container alignItems="center" spacing={2}>
                  {width >= 1500 ? (
                    <Grid item xs={5} md={4.5}>
                      <FormLabel>Data Inicio Levantamento</FormLabel>
                    </Grid>
                  ) : null}
                  <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                    <FormControl fullWidth>
                      <MaskStyle>
                        <input
                          type="date"
                          name="begin_date"
                          placeholder="dd/mm/yyyy"
                          min="1970-01-01"
                          value={dataInicioLevantamento}
                          onChange={(e) => {
                            setDataInicioLevantamento(e.target.value);
                            setDataFimLevantamento("");
                          }}
                        />
                      </MaskStyle>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

          {shoppingProccessStatus?.find(
            (e) =>
              e.description.toUpperCase() === "Coleta de Demandas".toUpperCase()
          )?.id === status &&
            status && (
              <Grid item xs={12} sm={6} md={4}>
                <Grid container alignItems="center" spacing={2}>
                  {width >= 1500 ? (
                    <Grid item xs={5} md={4.5}>
                      <FormLabel>Data Fim Levantamento</FormLabel>
                    </Grid>
                  ) : null}
                  <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                    <FormControl fullWidth>
                      <MaskStyle>
                        <input
                          type="date"
                          name="begin_date"
                          placeholder="dd/mm/yyyy"
                          min={dataInicioLevantamento}
                          value={dataFimLevantamento}
                          disabled={!dataInicioLevantamento}
                          onChange={(e) =>
                            setDataFimLevantamento(e.target.value)
                          }
                        />
                      </MaskStyle>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

          {shoppingProccessStatus?.find(
            (e) =>
              e.description.toUpperCase() === "Coleta de Demandas".toUpperCase()
          )?.id === status &&
            status && <Grid item xs={12} sm={6} md={4}></Grid>}

          {shoppingProccessStatus?.find(
            (e) => e.description.toUpperCase() === "Em consumo".toUpperCase()
          )?.id === status &&
            status && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container alignItems="center" spacing={2}>
                    {width >= 1500 ? (
                      <Grid item xs={5} md={4.5}>
                        <FormLabel>Data Inicio Vigência</FormLabel>
                      </Grid>
                    ) : null}
                    <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                      <FormControl fullWidth>
                        <MaskStyle>
                          <input
                            type="date"
                            name="begin_date"
                            placeholder="dd/mm/yyyy"
                            min="1970-01-01"
                            value={dataInicioVigencia}
                            onChange={(e) => {
                              setDataInicioVigencia(e.target.value);
                              setDataFimVigencia("");
                            }}
                          />
                        </MaskStyle>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Grid container alignItems="center" spacing={2}>
                    {width >= 1500 ? (
                      <Grid item xs={5} md={4.5}>
                        <FormLabel>Data Fim Vigência</FormLabel>
                      </Grid>
                    ) : null}
                    <Grid item xs={7} md={width >= 1500 ? 7.5 : 12}>
                      <FormControl fullWidth>
                        <MaskStyle>
                          <input
                            type="date"
                            name="begin_date"
                            placeholder="dd/mm/yyyy"
                            min={dataInicioVigencia}
                            disabled={!dataInicioVigencia}
                            value={dataFimVigencia}
                            onChange={(e) => setDataFimVigencia(e.target.value)}
                          />
                        </MaskStyle>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

          {shoppingProcesSelect?.id &&
            shoppingProcesSelect?.statusId !== status && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="observations"
                  label={translations[param]["note_about_changing_status"]}
                  multiline
                  value={observationStatusPC}
                  onChange={(e) => setObservationStatusPC(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}

          {shoppingProccessStatus?.find(
            (e) =>
              e.description.toUpperCase() === "Coleta de Demandas".toUpperCase()
          )?.id === status &&
            status && (
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  {width >= 1500 ? (
                    <Grid item xs={3} sm={2} md={1.5}>
                      <FormLabel>Carta Convite:</FormLabel>
                    </Grid>
                  ) : null}
                  <Grid item xs={9} sm={10} md={width >= 1500 ? 10.5 : 12}>
                    <TextField
                      size="small"
                      id="process"
                      name="process"
                      label="Carta Convite"
                      variant="outlined"
                      value={cartaConvite}
                      onChange={(e) => setCartaConvite(e.target.value)}
                      fullWidth
                      multiline
                      inputProps={{
                        style: { maxHeight: 200, overflow: "auto" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </Card>
      <Card sx={{ padding: 2, marginTop: 3, width: "100%" }}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          xs={5}
        >
          <Grid item>
            <FormLabel>{translations[param]["items"]}</FormLabel>
          </Grid>
          <Grid item>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e: any) => setShowViewItems(e.target.value)}
              value={showViewItems}
            >
              <FormControlLabel
                value={ViewItems.Negociados}
                control={<Radio size="small" />}
                label="Negociados"
              />
              <FormControlLabel
                value={ViewItems.Demandas}
                control={<Radio size="small" />}
                label="Demandas"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Divider
          sx={{ marginBottom: 2, backgroundColor: palette.primary.main }}
        />
        {showViewItems === ViewItems.Negociados ? (
          <>
            <Grid sx={{ marginBottom: 2 }}>
              <ItemCustomStart>
                <ButtonInclude
                  label={translations[param]["include"]}
                  onClick={async () => {
                    handleSelected(null);
                    if (shoppingProcesSelect?.id) {
                      setOpenDialog(true);
                    } else {
                      const response = await handleNewSalveShoppingProcess();
                      if (shoppingProcesSelect?.id || response?.id) {
                        await getProductsToDataDialog(response);
                        setOpenDialog(true);
                      }
                    }
                  }}
                />
                <ButtonDefault
                  label="Importar"
                  onClick={() => setDialogImport(true)}
                  disabled={shoppingProcesSelect?.id ? false : true}
                />
              </ItemCustomStart>
            </Grid>
            {loadingShoppingProcessItem ? (
              <CircularLoading />
            ) : shoppingProcessItem.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                funtionToOrder={(item) =>
                  item?.orderBackend &&
                  setOrderBy({
                    number: item?.orderBackend,
                    order: item?.order === "asc" ? true : false,
                  })
                }
              >
                {shoppingProcessItem.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item.codeProduct}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item.descrptionProduct}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item.buyerDescription}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item.unitDescription}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item.quantity}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialog(true);
                      }}
                    >
                      {parseFloat(item.referenceValue).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialog(true);
                      }}
                    >
                      {(
                        item.quantity * parseFloat(item.referenceValue)
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelected(item);
                        setOpenDialogThree(true);
                      }}
                    >
                      <PaidOutlinedIcon color="error" />
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        Swal.fire({
                          title: translations[param]["attention"],
                          text: translations[param][
                            "delete_item_shopping_proccess"
                          ],
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: palette.primary.main,
                          cancelButtonColor: palette.error.main,
                          confirmButtonText: translations[param]["yes"],
                          cancelButtonText: translations[param]["no"],
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleDelete(item);
                          }
                        })
                      }
                    >
                      <DeleteIcon color="error" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </>
        ) : (
          <GridDemands />
        )}
      </Card>

      <Card sx={{ marginTop: "2%" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{`${translations[param]["progress"]} ${translations[param]["purchasing_processes"]}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {statusProgress.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={[
                  {
                    label: translations[param]["status"],
                  },
                  {
                    label: translations[param]["date"],
                  },
                  {
                    label: translations[param]["user_responsible"],
                  },
                  {
                    label: translations[param]["observation"],
                  },
                ]}
                page={{
                  page: 0,
                  rowsPerPage: 500,
                  total: statusProgress.length,
                }}
                handleChangePage={() => {}}
                handleChangeRowsPerPage={() => {}}
              >
                {statusProgress.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item.processoCompraStatus}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.data &&
                        format(
                          addHours(new Date(item.data), 3),
                          "dd/MM/yyyy HH:mm:ss"
                        )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.usuarioSistemaResponsavel}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.observacao}
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </AccordionDetails>
        </Accordion>
      </Card>

      <Grid
        display="flex"
        justifyContent="end"
        alignItems="center"
        sx={{ marginTop: 5 }}
      >
        {shoppingProcesSelect?.statusId ===
          (shoppingProccessStatus?.find(
            (e) =>
              e.description.toUpperCase() === "Em configuração".toUpperCase()
          )?.id as string) && (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const newStatus = shoppingProccessStatus?.find(
                (e) => e.description.toUpperCase() === "Planejado".toUpperCase()
              )?.id;

              setStatus(newStatus!);

              Swal.fire({
                title: translations[param]["attention"],
                text: "Deseja iniciar o planejamento ?",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: palette.primary.main,
                cancelButtonColor: palette.error.main,
                confirmButtonText: translations[param]["yes"],
                cancelButtonText: translations[param]["no"],
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await shoppingProcessConsumer
                    .get(
                      page,
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      orderBy,
                      true,
                      `${shoppingProcesSelect!.id}`
                    )
                    .finally(() => handleUpdateShoppingProcess(newStatus!));
                }
              });
            }}
            disabled={!shoppingProcesSelect?.id}
            color="secondary"
            startIcon={<AssessmentOutlinedIcon color="primary" />}
            type="button"
            sx={{ marginRight: 1 }}
          >
            Planejamento
          </Button>
        )}
        {shoppingProcesSelect?.id && showViewItems === ViewItems.Negociados && (
          <ButtonOrderGenerate
            label="Gerar PDF - Negociação"
            onClick={async () => {
              await getShoppingProcessItem(`${shoppingProcesSelect?.id}`, 9999);

              Swal.fire({
                title: translations[param]["attention"],
                text: "PDF gerado com sucesso, deseja imprimir ?",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: palette.primary.main,
                cancelButtonColor: palette.error.main,
                confirmButtonText: translations[param]["yes"],
                cancelButtonText: translations[param]["no"],
              }).then((result) => {
                if (result.isConfirmed) {
                  router(`/${lang}/shopping/process/pdf`);
                }
              });
            }}
          />
        )}
        {shoppingProcesSelect?.id && showViewItems === ViewItems.Demandas && (
          <ButtonOrderGenerate
            label="Gerar PDF - Demanda"
            onClick={async () => {
              await getDemands(`${shoppingProcesSelect?.id}`, 9999);
              await getCompanysParticipating(
                `${shoppingProcesSelect?.id}`,
                9999
              );

              Swal.fire({
                title: translations[param]["attention"],
                text: "PDF gerado com sucesso, deseja imprimir ?",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: palette.primary.main,
                cancelButtonColor: palette.error.main,
                confirmButtonText: translations[param]["yes"],
                cancelButtonText: translations[param]["no"],
              }).then((result) => {
                if (result.isConfirmed) {
                  router(`/${lang}/shopping/process/pdf/demandas`);
                }
              });
            }}
          />
        )}
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            requestDemands(
              shoppingProcesSelect,
              participatingCompanies.filter(
                (e: any) => !e.declinouParticipacao && e
              )
            )
          }
          disabled={!shoppingProcesSelect?.id}
          color="secondary"
          startIcon={<PostAddOutlinedIcon color="success" />}
          type="button"
        >
          Solicitar demanda
        </Button>
        <ButtonClosedForm
          label="Fechar"
          onClick={() => {
            handleSelectedShoppingProcess(null);
            router(-1);
          }}
        />
        {shoppingProcesSelect?.id ? (
          <ButtonSalveForm
            label={translations[param]["update"]}
            colorIcon="success"
            onClick={() => handleUpdateShoppingProcess()}
          />
        ) : (
          <ButtonSalveForm
            label={translations[param]["save"]}
            colorIcon="success"
            disabled={shoppingProcesSelect !== null ? true : false}
            onClick={handleNewSalveShoppingProcess}
          />
        )}
      </Grid>

      <DialogComponent
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {`${translations[param]["items"]} ${translations[param]["purchasing_processes"]}`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialog(false);
            handleSelected(null);
            setNameSearch("");
            setCodeProduct("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider sx={{ backgroundColor: palette.primary.main }} />
        <DialogContent>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid xs={12} sm={12} md={12}>
              <ItemCustom>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "14%" }}>
                    {translations[param]["code"]}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    id="show_select_code"
                    options={productsToSearch}
                    value={productSelect}
                    getOptionLabel={(option) => {
                      if (option) {
                        return `${option?.code} - ${option.label}`;
                      }
                      return "";
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setProductId(value.id);
                        setProductGeneralId(value?.produtoId);
                        setProductSelect(value);
                        setCodeProduct(value?.code);
                        setProductCode(value?.code);
                        setProductLabel(value?.label);
                      } else {
                        setProductId("");
                        setProductGeneralId("");
                        setProductSelect("");
                        setCodeProduct("");
                        setProductCode("");
                        setProductLabel("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e) => setCodeProduct(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={() => {
                    setDescriptionProduct("");
                    setClick(true);
                    setPage({
                      ...page,
                      page: 0,
                      total: 0,
                    });
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <ItemCustom>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "14%" }}>
                    {translations[param]["description"]}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    id="show_select_description"
                    options={productsToSearch}
                    value={productSelect}
                    getOptionLabel={(option) => {
                      if (option) {
                        return option.label;
                      }
                      return "";
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setProductId(value.id);
                        setProductGeneralId(value?.produtoId);
                        setProductSelect(value);
                        setDescriptionProduct(value?.label);
                        setProductLabel(value?.label);
                      } else {
                        setProductId("");
                        setProductGeneralId("");
                        setProductSelect("");
                        setDescriptionProduct("");
                        setProductLabel("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e) => setDescriptionProduct(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
                <IconButton
                  aria-label="search-secondary"
                  color="primary"
                  onClick={() => {
                    setClick(true);
                    setCodeProduct("");
                    setPage({
                      ...page,
                      page: 0,
                      total: 0,
                    });
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <ItemCustom>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "14%" }}>
                    {`${translations[param]["company"]} ${translations[param]["buyer"]}`}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="show_select_buyer_company"
                    options={companysToSearchBuyer}
                    value={buyerValue}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setBuyerValue(value);
                        setBuyerId(value.id);
                        setBuyerName(value.label);
                        getCompanysContacts(value.id, 1000);
                      } else {
                        setBuyerValue("");
                        setBuyerId("");
                        setBuyerName("");
                        getCompanysContacts("");
                      }
                    }}
                    renderInput={(params) =>
                      loadingSelectSearch ? (
                        <CircularLoading />
                      ) : (
                        <TextField
                          {...params}
                          label="Selecione:"
                          size="small"
                          required
                          onChange={(e) => {
                            setNameSearch(e.target.value);
                          }}
                        />
                      )
                    }
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <ItemCustom>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "14%" }}>
                    {`${translations[param]["responsible"]} ${translations[param]["buyer"]}`}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="show_select_buyer_responsible"
                    options={companysContactToSearch}
                    value={companyContactValue}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setCompanyContactValue(value);
                        setCompanyContactId(value.id);
                        setCompanyContact(value.label);
                      } else {
                        setCompanyContactValue("");
                        setCompanyContactId("");
                        setCompanyContact("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                      />
                    )}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <ItemCustom>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "14%" }}>
                    {translations[param]["payment_condition"]}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="show_select_payment_condition"
                    options={paymentConditionsToSearch}
                    value={paymentConditionValue}
                    onChange={(_: any, value) => {
                      if (value?.id) {
                        setPaymentConditionValue(value);
                        setPaymentConditionId(value.id);
                        setPaymentCondition(value.label);
                      } else {
                        setPaymentConditionValue("");
                        setPaymentConditionId("");
                        setPaymentCondition("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                      />
                    )}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={3} sm={3} md={3}>
              <ItemCustomStart>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "57%" }}>
                    {translations[param]["quantity"]}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <TextField
                    label={translations[param]["quantity"]}
                    size="small"
                    type="number"
                    required
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                  />
                </FormControl>
              </ItemCustomStart>
            </Grid>
            <Grid xs={3} sm={3} md={3}>
              <ItemCustom>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "25%" }}>
                    {translations[param]["measure_unit"]}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="show_select_unit_of_measurement"
                    options={unitsToSearch}
                    componentsProps={{
                      popper: { style: { width: "fit-content" } },
                    }}
                    value={
                      unitsToSearch.find((item) => item.id === unitId)?.label
                    }
                    onChange={(_: any, value) => {
                      if (value.id) {
                        setUnitId(value.id);
                      } else {
                        setUnitId("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                      />
                    )}
                  />
                </FormControl>
              </ItemCustom>
            </Grid>
            <Grid xs={3} sm={3} md={3}>
              <ItemCustomStart>
                {width >= 1500 ? (
                  <FormLabel
                    sx={{ minWidth: "40%" }}
                  >{`${translations[param]["value"]} ${translations[param]["reference"]}`}</FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <TextField
                    label="R$ ##.###.##0,00"
                    size="small"
                    required
                    value={referenceValue}
                    onChange={(e) => {
                      e = formatCurrency(e);
                      setReferenceValue(e.target.value);
                    }}
                  />
                </FormControl>
              </ItemCustomStart>
            </Grid>
            <Grid xs={3} sm={3} md={3}>
              <ItemCustomStart>
                {width >= 1500 ? (
                  <FormLabel sx={{ minWidth: "40%" }}>
                    {translations[param]["target"]}
                  </FormLabel>
                ) : null}
                <FormControl fullWidth>
                  <TextField
                    label="R$ ##.###.##0,00"
                    size="small"
                    required
                    value={alvoValue}
                    onChange={(e) => {
                      e = formatCurrency(e);
                      setAlvoValue(e.target.value);
                    }}
                  />
                </FormControl>
              </ItemCustomStart>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              handleSelected(null);
              setOpenDialog(false);
              setNameSearch("");
              setCodeProduct("");
            }}
          />
          {shoppingProcesItemSelect?.id ? (
            <ButtonSalveForm
              label="Alterar"
              colorIcon="success"
              onClick={() => handleUpdate(shoppingProcesSelect!)}
            />
          ) : (
            <ButtonSalveForm
              label="Salvar"
              colorIcon="success"
              onClick={() => handleNewSalve(shoppingProcesSelect!)}
            />
          )}
        </DialogActions>
      </DialogComponent>

      <DialogComponent open={openDialogSecondary} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">
          {translations[param]["related_items"]}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialogSecondary(false);
            setClick(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loadingProductCompany ? (
            <CircularLoading />
          ) : products.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: translations[param]["code"],
                },
                {
                  label: translations[param]["description"],
                },
              ]}
              page={pageProduct}
              handleChangePage={handleChangePerPageProduct}
              handleChangeRowsPerPage={handleChangeRowsPerPageProduct}
            >
              {products.map((product) => (
                <TableRow
                  hover
                  key={product.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setProductSelect({
                      id: product.id,
                      code: product.codeProductCompany,
                      label: product.description,
                    });
                    setProductId(`${product.id}`);
                    setProductGeneralId(product?.productId);
                    setCodeProduct(product?.codeProductCompany);
                    setProductCode(product?.codeProductCompany);
                    setProductLabel(product?.description);
                    setDescriptionProduct(product?.description);
                    setOpenDialogSecondary(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {product.codeProductCompany}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialogSecondary(false);
              setClick(false);
            }}
          />
        </DialogActions>
      </DialogComponent>

      <DialogComponent open={openDialogThree} handleClose={() => {}}>
        <RatePCITem />
      </DialogComponent>

      <DialogComponent open={openDialogCompanyBuyer} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">
          {`${translations[param]["company"]} ${translations[param]["buyer"]}`}
          asdsad
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialogCompanyBuyer(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loadingSelectSearch ? (
            <CircularLoading />
          ) : (
            <TableComponent
              header={[
                {
                  label: "CNPJ",
                },
                {
                  label: translations[param]["description"],
                },
              ]}
              page={pageCompany}
              handleChangePage={handleChangePageCompany}
              handleChangeRowsPerPage={handleChangeRowsPerPageCompany}
            >
              {companysToSearchBuyer.map((value: any) => (
                <TableRow
                  hover
                  key={value.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (value?.id) {
                      setBuyerAdministratorValue(value);
                      setBuyerAdministratorId(value.id);
                      setBuyerAdministrator(value.label);
                      getCompanysContacts(value.id);
                      getPaymentConditions({
                        id: value.id,
                      } as iCompany);
                    } else {
                      setBuyerAdministratorValue("");
                      setBuyerAdministratorId("");
                      setBuyerAdministrator("");
                      getCompanysContacts("");
                    }
                    setOpenDialogCompanyBuyer(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {value.cnpj}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {value.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => setOpenDialogCompanyBuyer(false)}
          />
        </DialogActions>
      </DialogComponent>

      <DialogComponent open={isDialogOpen} handleClose={() => {}}>
        <DemandItem />
      </DialogComponent>

      <DialogComponent open={dialogImport} handleClose={() => {}}>
        {showViewItems === ViewItems.Negociados ? (
          <ImportItemDialog />
        ) : (
          <ImportItemDemandDialog />
        )}
      </DialogComponent>
    </>
  );
};

export default GeneralDate;
